import React from "react"
import LoginForm from "app/LoginForm"
import { SEO } from "components/SEO"
import SignedOutWrapper from "app/SignedOutWrapper"

function Login({ ...props }) {
  return (
    <SignedOutWrapper>
      <SEO title="Login" slug="/" />
      <LoginForm />
    </SignedOutWrapper>
  )
}

export default Login

Login.defaultProps = {}

Login.propTypes = {}
